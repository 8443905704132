import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faChevronLeft, faHome } from "@fortawesome/free-solid-svg-icons"

const Page = () => (
    <Layout container={false}>
        <SEO title="Vielen Dank!" />

        <div className="text-center" style={{fontSize:"1.5em",  padding: "80px" }}>
            <h1 className="text-center mt-4 mb-1">Vielen Dank</h1>
            <p className="text-center">Wir melden uns in Kürze bei Ihnen!</p>
            <Link className="btn btn-primary-outline text-center" to="/"><FontAwesomeIcon icon={faHome}></FontAwesomeIcon> Startseite</Link>
            <Link className="btn btn-success-outline text-center" to="/services">Services <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></Link>
        </div>

        <br /><br /><br/>
    </Layout>
)

export default Page
